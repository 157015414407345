import Glide from "@glidejs/glide";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

const imageGalleryCarousel = document.getElementsByClassName("image-gallery--carousel");
if(imageGalleryCarousel.length) {
  new Glide(".image-gallery--carousel", {
    type: "carousel",
    perView: 4,
    gap: 40,
    autoplay: 4000,
    keyboard: false,
    focusAt: 'center',
    peek: 80,
    breakpoints: {
      1024: {
        perView: 3
      },
      768: {
        perView: 1,
        gap: 20,
      }
    }
  }).mount()
}

const imageGalleryGallery = document.getElementsByClassName("image-gallery--gallery");
if(imageGalleryGallery.length) {
  new Glide(".image-gallery--gallery", {
    type: "carousel",
    perView: 2,
    gap: 40,
    autoplay: 4000,
    focusAt: "center",
    peek: 80,
    breakpoints: {
      1024: {
        perView: 1
      },
      768: {
        perView: 1,
        gap: 20,
      }
    }
  }).mount()
}

const instaPosts = document.getElementById("instaPosts");
if(instaPosts) {
  new Glide("#instaPosts", {
    type: "carousel",
    perView: 6,
    gap: 0,
    hoverPause: false,
    autoplay: 3000,
    animationDuration: 3000,
    animationTimingFunc: "linear",
    keyboard: false,
    dragThreshold: false,
    swipeThreshold: false,
    breakpoints: {
      768: {
        perView: 3
      }
    }
  }).mount();
}

import "/src/js/mobile-menu.js";
import "/src/styles/main.scss";
import "/node_modules/@glidejs/glide/src/assets/sass/glide.core.scss";
import "/node_modules/@glidejs/glide/src/assets/sass/glide.theme.scss";
import "/src/images/hero-texture.jpg";
import "/src/images/hero-texture--lg.jpg";
import "/src/images/illustrations/author.png";
import "/src/images/illustrations/journalist.png";
import "/src/images/illustrations/speaker.png";
import "/src/images/illustrations/consultant.png";
import "/src/images/illustrations/podcaster.png";
