const menuButton = document.getElementById("menuButton");
const mainNav = document.getElementById("mainNav");

menuButton.addEventListener("click", function () {
  this.classList.toggle("text-white");
  this.classList.toggle("fixed");
  this.classList.toggle("right-3");
  mainNav.classList.toggle("hidden");
  mainNav.classList.toggle("flex");
});
